import dynamic from 'next/dynamic';
import {isUserLoggedIn} from "utils/auth";
import {NextPageContext} from "next";
import {getState} from 'utils/misc';

const DefaultTheme = dynamic(() => import('./_default'));
const Meta = dynamic(() => import('components/Meta/_default'));
const FullPageLayout = dynamic(() => import('components/Layout/FullPageLayout/_default/FullPageLayout'));


interface Props {}

const LoginPage = (props: Props) => (
  <>
    <Meta
      title='Login'
      urlPath='login'
    />
    <DefaultTheme {...props}/>
  </>
);

export const getServerSideProps = (ctx: NextPageContext) => {
  if (isUserLoggedIn(ctx)) {
    return {
      redirect: {
        destination: `/?${new URLSearchParams(getState(ctx)).toString()}`,
        permanent: false,
      },
    };
  } else {
    return {
      props: {},
    };
  }
}

LoginPage.getLayout = (page: React.ReactElement) => <FullPageLayout>{page}</FullPageLayout>

export default LoginPage;
